.btn.btn-square {
  border-radius: 0;
  border: 0px;
  margin: 0px;
  padding: 30px;
  font-size: 20pt;
}

.hut-sign {
  height: 125px;
  width: 220px;
  float: left;
  margin: 5px;
  margin-left: 5%;
}

.border-warning {
  border-width: 2px !important;
}

.navbar.navbar-dark {
  background-color: #243386 !important;
}

.btn.btn-dark {
  background-color: #243386 !important;
}

.btn.btn-dark:hover {
  background-color: #fdf14b !important;
  color: #000000 !important;
}

.about-us {
  font-size: 18pt;
}

.carwash-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.page-content {
  margin-left: 10%;
  margin-right: 10%;
}

.wash {
  width: 100%;
  height: 100%;
  transition: width 0.75s;
}

.wash:hover {
  width: 100%;
}

.wash-card-price {
  text-align: right;
  font-size: 1.5em;
}
